import * as PatientGroupActions from '../actions/update.actions';

const printOptionsDefault = {
  solidPackOption: {
    isPrintStartDate: false,
    isPrintDateOfWeek: false,
    isPrintDoseTimeHeadings: false,
    isReverseStartDayOnTheWeek: false,
    isReverseDisplayOffTimeSlots: false,
    isPrintDrugWarning: false,
    isPrintSameAs: false,
    isPrintOnPottles: false,
    isPatientNameOnPottles: false,
    isPrintNonPackedAndPRN: false,
    isPrintMedicationScheduleTable: false,
    displayQuality: '0',
    drugPrintFormat: '0',
    guidedFillPacking: '0',
    isDoNotCrush: false,
    isSigningChartAtBottom: false,
  },
  perfPackOption: {
    isPrintStartDate: false,
    isPrintDateOfWeek: false,
    isPrintDoseTimeHeadings: false,
    isReverseStartDayOnTheWeek: false,
    isReverseDisplayOffTimeSlots: false,
    isPrintDrugWarning: false,
    isPrintSameAs: false,
    isPrintOnPottles: false,
    isPatientNameOnPottles: false,
    isPrintNonPackedAndPRN: false,
    isPrintMedicationScheduleTable: false,
    displayQuality: '0',
    drugPrintFormat: '0',
    guidedFillPacking: '0',
    isDoNotCrush: false,
    isSigningChartAtBottom: false,
  },
  trifoldPackOption: {
    isPrintStartDate: false,
    isPrintDateOfWeek: false,
    isPrintDoseTimeHeadings: false,
    isReverseStartDayOnTheWeek: false,
    isReverseDisplayOffTimeSlots: false,
    isPrintDrugWarning: false,
    isPrintSameAs: false,
    isPrintOnPottles: false,
    isPatientNameOnPottles: false,
    isPrintNonPackedAndPRN: false,
    isPrintMedicationScheduleTable: false,
    displayQuality: '0',
    drugPrintFormat: '0',
    guidedFillPacking: '0',
    isDoNotCrush: false,
    isSigningChartAtBottom: false,
  },
  medicoPackOption: {
    isPrintStartDate: false,
    isPrintDateOfWeek: false,
    isPrintDoseTimeHeadings: false,
    isReverseStartDayOnTheWeek: false,
    isReverseDisplayOffTimeSlots: false,
    isPrintDrugWarning: false,
    isPrintSameAs: false,
    isPrintOnPottles: false,
    isPatientNameOnPottles: false,
    isPrintNonPackedAndPRN: false,
    isPrintMedicationScheduleTable: false,
    displayQuality: '0',
    drugPrintFormat: '0',
    guidedFillPacking: '0',
    isDoNotCrush: false,
    isSigningChartAtBottom: false,
  },
};

const initialState = {
  id: '',
  data: {
    code: '',
    name: '',
    dispenseCode: '',
    racfid: '',
    patientGroupType: 0,
    street: '',
    email: '',
    suburb: '',
    state: '',
    postalCode: '',
    packType: '',
    fax: '',
    logoId: '',
    beds: 0,
    phone: '',
    manifest: 0,
    status: 0,
    isListPatientByRoom: 'false',
    isUseGenericAsDrugName: 'false',
    isAutomation: 'false',
    defaultTimeslot: [...Array(4)].fill(''),
    sachetTimeslot: [...Array(8)].fill(''),
    shortCourseTimeslot: [...Array(8)].fill(''),
    language: '',
    defaultPackType: 0,
  },
  printOptions: printOptionsDefault,
  loading: false,
  submitting: false,
  error: null,
  message: '',
};

const update = (state = initialState, action) => {
  switch (action.type) {
    // GET_PATIENT_GROUP
    case PatientGroupActions.GET_PATIENT_GROUP:
      return {
        ...state,
        loading: true,
      };
    case PatientGroupActions.GET_PATIENT_GROUP_SUCCESS:
      return {
        ...state,
        id: action.payload.data.id,
        loading: false,
        data: {
          code: action.payload.data.code,
          name: action.payload.data.name,
          dispenseCode: action.payload.data.dispenseCode,
          racfid: action.payload.data.racfid,
          patientGroupType: action.payload.data.patientGroupType,
          street: action.payload.data.street,
          email: action.payload.data.email,
          suburb: action.payload.data.suburb,
          state: action.payload.data.state,
          postalCode: action.payload.data.postalCode,
          packType: action.payload.data.packType,
          fax: action.payload.data.fax,
          logoId: action.payload.data.logoId,
          beds: action.payload.data.beds,
          phone: action.payload.data.phone,
          manifest: action.payload.data.manifest,
          isListPatientByRoom: action.payload.data.isListPatientByRoom, // Parse value to match with constants
          isUseGenericAsDrugName: action.payload.data.isUseGenericAsDrugName, // Parse value to match with constants
          isAutomation: action.payload.data.isAutomation,
          defaultTimeslot: action.payload.data.defaultTimeslot,
          sachetTimeslot: action.payload.data.sachetTimeslot,
          shortCourseTimeslot: action.payload.data.shortCourseTimeslot,
          status: action.payload.data.status,
          language: action.payload.data.language,
          defaultPackType: action.payload.data.defaultPackType,
        },
      };
    case PatientGroupActions.GET_PATIENT_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: action.payload.error,
      };

		// GET_PACK_OPTION
		// case PatientGroupActions.GET_PACK_OPTION:
		// 	return {
		// 		...state,
		// 		loading: true
		// 	};
		case PatientGroupActions.GET_PACK_OPTION_SUCCESS:
			return {
				...state,
				printOptions: { ...state.printOptions, ...action.payload.data }
			};
		case PatientGroupActions.GET_PACK_OPTION_FAIL:
			return {
				...state,
				message: action.payload.message,
				error: action.payload.error
			};

    // CHANGE_PATIENT_GROUP_UPDATE_FORM
    case PatientGroupActions.CHANGE_PATIENT_GROUP_UPDATE_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };
    // UPDATE_PATIENT_GROUP
    case PatientGroupActions.UPDATE_PATIENT_GROUP:
      return {
        ...state,
        submitting: true,
      };
    case PatientGroupActions.UPDATE_PATIENT_GROUP_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: false,
      };
    case PatientGroupActions.UPDATE_PATIENT_GROUP_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload.error,
        message: action.payload.message,
      };
    // DELETE_PATIENT_GROUP_START
    case PatientGroupActions.DELETE_PATIENT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case PatientGroupActions.DELETE_PATIENT_GROUP_DONE:
      return {
        ...state,
        loading: false,
      };
    // UPDATE_PATIENT_GROUP_STATUS
    case PatientGroupActions.UPDATE_PATIENT_GROUP_STATUS:
      return {
        ...state,
        submitting: true,
      };
    case PatientGroupActions.UPDATE_PATIENT_GROUP_STATUS_SUCCESS:
      return {
        ...state,
        submitting: false,
        data: {
          ...state.data,
          status: action.payload,
        },
      };
    case PatientGroupActions.UPDATE_PATIENT_GROUP_STATUS_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload.error,
        message: action.payload.message,
      };

		// UPDATE_PACK_OPTION
		case PatientGroupActions.UPDATE_PACK_OPTION:
			return {
				...state,
				submitting: true
			};
		case PatientGroupActions.UPDATE_PACK_OPTION_SUCCESS:
			return {
				...state,
				submitting: false,
				printOptions: { ...state.printOptions, ...action.payload.data }
			};
		case PatientGroupActions.UPDATE_PACK_OPTION_FAIL:
			return {
				...state,
				submitting: false,
				error: action.payload.error,
				message: action.payload.message
			};

		// CLEAR_UPDATE_FORM
		case PatientGroupActions.CLEAR_UPDATE_FORM:
			return initialState;
		default: {
			return state;
		}
	}
};

export default update;
